
export type ScrollDirection = 'left' | 'right' | 'last' | 'first';

export const scrollTo = (pos: ScrollDirection) => {
  const scrollerContainer = document.getElementById('tabNav') as HTMLElement;
  if (scrollerContainer) {
    const targetRect = scrollerContainer.getBoundingClientRect();
    switch (pos) {
    case 'left':
      scrollerContainer.scrollBy({ left: -targetRect.width, behavior: 'smooth' });
      break;
    case 'right':
      scrollerContainer.scrollBy({ left: targetRect.width, behavior: 'smooth' });
      break;
    case 'last':
      scrollerContainer.scrollBy({ left: scrollerContainer.scrollWidth, behavior: 'smooth' });
      break;
    case 'first':
      scrollerContainer.scrollBy({ left: -scrollerContainer.scrollWidth, behavior: 'smooth' });
      break;
    default:
      break;
    }
  }
};
