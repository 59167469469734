import MotifButton from '@ey-xd/motif-react/components/button';
import MotifFooter from '@ey-xd/motif-react/components/footer';
import React from 'react';
import EYLogo from '../../assets/images/ey-logo.svg';
import './index.scss';
import { FooterLinksData } from '../../interfaces';

interface FooterProps {
  footerCTA?: string;
  footerContent?: string;
  footerLink?: string;
  footnote?: string;
  full?: boolean;
  footerLinks?: Array<FooterLinksData>
}

const showYellowBar = (
  footerCTA: string | undefined,
  footerContent: string | undefined,
  footerLink: string | undefined) =>
  (footerCTA === '' && footerContent === '' && footerLink === '');

const FooterComponent: React.FC<FooterProps> = ({
  footerCTA,
  footerContent,
  footerLink,
  footnote,
  full,
  footerLinks
}: FooterProps) => (
  <>
    <div className="eoy-footer-container">
      {footerLinks && footerLinks.length > 0 && (
        <div className="eoy-page-links">
          {footerLinks?.slice(0, 5).map((footer) => (
            <a
              className="eoy-footer-link-item"
              key={footer.label}
              href={footer.url}
              target="_blank"
              rel="noreferrer"
            >
              {footer.label}
            </a>
          ))}
        </div>
      )}
      {full && !showYellowBar(footerCTA, footerContent, footerLink) && (
        <div className="eoy-footer-banner">
          <div className="motif-container">
            <div className="motif-row motif-middle-xs">
              <div className="motif-col-xs-12 motif-col-md-4 motif-center-xs motif-start-md">
                <h2 className="eoy-footer-banner-text">{footerContent}</h2>
              </div>
              <div className="motif-col-xs-12 motif-col-md-4 motif-center-xs motif-end-md eoy-button">
                <MotifButton
                  size="large"
                  onClick={() => {
                    window.open(footerLink, '_blank');
                  }}
                  className="eoy-footer-button"
                >
                  {footerCTA}
                </MotifButton>
              </div>
            </div>
          </div>
        </div>
      )}
      <MotifFooter className={`eoy-footer ${!full && `eoy-footer-slim`}`}>
        <a className="eoy-footer-icon-link" href="https://www.ey.com/en_us">
          <img
            src={EYLogo}
            aria-hidden="true"
            className="eoy-footer-icon"
            alt="EY logo"
          />
          <span className="hide-for-accessibility">EY Logo - Home</span>
        </a>
        {full && <p className="eoy-footer-disclaimer">{footnote}</p>}
      </MotifFooter>
    </div>
  </>
);

FooterComponent.defaultProps = {
  full: true,
  footerContent: '',
  footerCTA: '',
  footnote: '',
  footerLink: '',
  footerLinks: []
};

export default FooterComponent;
