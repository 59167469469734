import {
  MotifDropdownPortal,
  MotifFormField,
  MotifTree,
  MotifLabel,
  MotifOption,
  MotifSearch,
  MotifSelect
} from '@ey-xd/motif-react';
import MotifTheme from '@ey-xd/motif-react/components/theme';
import MotifIcon from '@ey-xd/motif-react/components/icon';
import MotifPagination from '@ey-xd/motif-react/components/pagination';
import MotifProgressBar from '@ey-xd/motif-react/components/progress-bar';
import MotifHeader, { MotifHeaderLogo } from '@ey-xd/motif-react/components/header';
import MotifTabNavigation, { MotifTabControl } from '@ey-xd/motif-react/components/tab-navigation';
import MotifButton, { MotifIconButton } from '@ey-xd/motif-react/components/button';
import {
  navigationIcClose24px,
  navigationIcExpandMore24px,
  navigationIcChevronLeft24px,
  navigationIcFirstPage24px,
  navigationIcChevronRight24px,
  navigationIcLastPage24px
} from '@ey-xd/motif-react/assets/icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import FocusLock, { AutoFocusInside, InFocusGuard } from 'react-focus-lock';
import { useNavigate, useParams } from 'react-router-dom';
import EYLogo from '../assets/images/ey-logo.svg';
import CardComponent from '../components/card';
import CheckboxFilterComponent from '../components/checkboxFilter';
import FooterComponent from '../components/footer';
import HeroComponent from '../components/hero';
import MetaTags from '../components/meta';
import ModalWrapper from '../components/modalWrapper';
import { Member, PageContext, RegionData, SiteContent, YearFilters } from '../interfaces';
import {
  filterByYear,
  filterCheckedValues,
  filterSearchTerms,
  getMaxPages,
  getPaginationBounds,
  getSelectedMember,
  filterCountriesByRegions,
  sortedByCountry,
  sortedByName
} from '../utils';
import './index.scss';
import CookieConsentComponent from '../components/cookieConsent';
import TreeComponent from '../components/tree';
import { scrollTo } from './scroll';

const eyUrl = 'https://www.ey.com';

interface IndexProps {
  pageData: PageContext;
  itemsPerPage?: number;
  searchAll?: boolean;
}

const IndexPage: React.FC<IndexProps> = ({ pageData, itemsPerPage = 20, searchAll = false }) => {
  const { memberId } = useParams();
  const navigate = useNavigate();
  const scrollContainerRef = useRef<HTMLElement>(null);

  // initial data parsing and function creation
  const { members, sectors, siteContent, siteCookies, years } = pageData;
  const {
    searchPlaceholder,
    siteTitle,
    heroTitle,
    heroImage,
    footnote,
    footerCTA,
    footerContent,
    footerLink,
    footerLinks
  } = siteContent as SiteContent;

  const regionData: RegionData[] = filterCountriesByRegions(members);
  const resetFilters = () => sectors.map(() => false);

  // state variables
  const [modalVisible, setModalVisibility] =
    useState<boolean>(getSelectedMember({ value: memberId, members }).length > 0);
  const [selectedNode, setSelectedNode] =
    useState<Member[]>(getSelectedMember({ value: memberId, members }));

  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const [checkedSectorFilters, setCheckedSectorFilters] =
    useState<boolean[]>(resetFilters());

  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [currentlyOpenItem, setCurrentlyOpenItem] = useState<string | null>(null);
  const [filteredData, setFilteredData] = useState<Member[]>(members);
  const [searchedData, setSearchedData] = useState<Member[]>(members);

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState<string | number>(1);
  const [fontsLoaded, setFontsLoaded] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [isRightDisabled, setIsRightDisabled] = useState(false);
  let scrollerContainer: HTMLElement;

  const metadata = {
    ...siteContent,
    fullName: selectedNode.length ? selectedNode[0].fullName : null,
    company: selectedNode.length ? selectedNode[0].company : null,
    image: selectedNode.length ? selectedNode[0].headshot : null
  };

  // useEffect to list for change in memberId - search params
  useEffect(() => {
    setModalVisibility(getSelectedMember({ value: memberId, members }).length > 0);
    setSelectedNode(getSelectedMember({ value: memberId, members }));
  }, [memberId]);

  useEffect(() => {
    buttonAllClicked();
    document.fonts.ready.then(() => {
      setFontsLoaded(true);
      document.body.classList.add('eoy-app-loaded');
    });
    scrollerContainer = document.getElementById('tabNav') as HTMLElement;
    setIsLeftDisabled(scrollerContainer.scrollLeft === 0);
    setIsRightDisabled(
      scrollerContainer.scrollLeft + scrollerContainer.offsetWidth >= scrollerContainer.scrollWidth
    );
  }, []);

  const handleScrollEnd = useCallback(() => {
    setIsLeftDisabled(scrollerContainer.scrollLeft === 0);
    setIsRightDisabled(
      scrollerContainer.scrollLeft + scrollerContainer.offsetWidth >=
        scrollerContainer.scrollWidth
    );
  }, []);

  useEffect(() => {
    scrollerContainer.addEventListener('scrollend', handleScrollEnd);

    return () => {
      window.removeEventListener('click', handleScrollEnd);
    };
  }, [handleScrollEnd]);

  useEffect(() => {
    if (isChecked) {
      const filterData = filterCheckedValues({ members: getSearchStyle(), checkedSectorFilters, checkedItems, sectors });
      // Update filtered data when checked filters change
      setFilteredData(filterData.length ? filterData : getSearchStyle());
    }
  }, [checkedSectorFilters, checkedItems]);

  /**
  * handleInputChange - function that handles a search input change
  * @param searchValue: value to search against
  * Will reset values and subsequently set filtered data
  */
  const handleInputChange = (searchValue: string) => {
    const filterData: Member[] = filterSearchTerms({ value: searchValue, members });
    setCurrentPage(1);
    setTabState(0);
    setCheckedSectorFilters(resetFilters());
    setCheckedItems([]);
    setIsChecked(false);
    setSearchInputValue(searchValue);
    setSearchedData(filterData);
    setFilteredData(filterData);
  };

  const getSearchStyle = (): Member[] => (searchAll ? members : searchedData);

  /**
   *
   * @param value year to filter values by
   * @param idx tab index clicked
   * Will reset values and subsequently set filtered data
   */
  const handleTabControlClick = (value: string, idx: number) => {
    const filterData: Member[] = filterByYear({ value, members: getSearchStyle() });

    setCurrentPage(1);
    setTabState(idx);
    setIsChecked(false);
    setCheckedSectorFilters(resetFilters());
    setCheckedItems([]);
    if (searchAll) {
      setSearchInputValue('');
    }
    setFilteredData(filterData);
  };

  /**
   *
   * @param idx sets active value
   * uses vanillaJS to parse through the dom and set the active class
   * addresses a flaw of motif.
   */
  const setTabState = (idx: number) => {
    Array.from(document.querySelectorAll('.motif-tab-button')).forEach((item, index) => {
      (idx === index) ? item.classList.add('motif-active') : item.classList.remove('motif-active');
    });
  };

  const removeTabListActiveSelection = () => {
    Array.from(document.querySelectorAll('.motif-tab-button')).forEach((item) => {
      item.classList.remove('motif-active');
    });
  };

  /**
   *
   * @param selectedIdx index to set checked value of checkbox members
   * updates the checked filters array as well as resets the data as necessary.
   */
  const handleCheckboxChange = (selectedIdx: number) => {
    const updatedFilters = checkedSectorFilters.map((value: boolean, idx: number) => (selectedIdx === idx ? !value : value));
    setCurrentPage(1);
    setTabState(0);
    if (searchAll) {
      setSearchInputValue('');
    }
    setCheckedSectorFilters(updatedFilters);
    setIsChecked(true);
  };

  const handleChecked = (key: string, checked: boolean) => {
    setIsChecked(true);
    setCheckedItems((prev) => {
      if (checked) {
        return [...prev, key];
      }

      return prev.filter((item) => item !== key);
    });
  };

  const handleNodeClick = (key: string) => {
    setCurrentlyOpenItem(key === currentlyOpenItem ? null : key);
  };

  /**
   * resets checked values in filter and restores all members.
   */
  const handleResetButton = () => {
    setCurrentPage(1);
    setCheckedSectorFilters(resetFilters());
    setFilteredData(members);
    setSearchedData(members);
  };

  const handleResetButtonMobile = () => {
    setCurrentPage(1);
    setCheckedItems([]);
    setCurrentlyOpenItem(null);
    setCheckedSectorFilters(resetFilters());
    setFilteredData(members);
    setSearchedData(members);
  };


  /**
   *
   * @param id unique id to check against and display modal.
   * selects and navigates to the modal.
   */
  const handleShow = (id: string) => {
    setModalVisibility(true);
    setSelectedNode(getSelectedMember({ value: id, members }));
    navigate(`/member/${id}`);
  };

  /**
   * closes and navigates to the root url
   */
  const handleClose = () => {
    setModalVisibility(false);
    navigate(`/`);
    const { body } = document;
    body.style.overflow = 'auto';
  };

  /**
   * handles filter dropdown
   */
  const handleDropdown = () => {
    setDropdownIsOpen(!dropdownIsOpen);
  };

  const handleClickOutside = () => {
    setDropdownIsOpen(false);
  };

  const handlePageChange = (val: string | number) => {
    window.scrollTo(0, 0);
    setCurrentPage(val);
  };

  /**
   *
   * @returns string
   *  utility to display selected members proper verbiage
   */
  const getSelectedCount = () => `${filteredData.length} ${filteredData.length === 1 ? 'Member' : 'Members'}`;

  /**
   *
   * @returns active filters selected
   */
  const getFilteredDropdownCount = () => {
    const count = checkedSectorFilters.filter((value) => value).length + checkedItems.length;
    if (checkedSectorFilters.filter((value) => value).length > 0 || checkedItems.length > 0) {
      return count && ` (${count})`;
    }
  };

  /**
   * utility to creates yearFilters array - needed to populate  tabset
   */
  const yearFilters: YearFilters[] = [];
  years.forEach(node => {
    const { year } = node;
    yearFilters.push({ label: year, value: year });
  });

  /**
   * accessibility logic for filter dropdown on tablet and mobile
   */
  const ref = React.useRef(null);

  const handleKeyDown = (event: { key: string; }) => {
    if (event.key === 'Escape') {
      setDropdownIsOpen(false);
    }
  };

  /**
   * determines click or touch event for tab filters
   */
  const supportsTouch = 'ontouchstart' in window;

  const getTouchTab = (value: string, idx: number) => {
    if (supportsTouch) {
      document.getElementById('selectAllBtn')?.classList.remove('eoy-all-button-active');
      handleTabControlClick(value, idx);
    }
  };

  const getClickTab = (value: string, idx: number) => {
    if (!supportsTouch) {
      document.getElementById('selectAllBtn')?.classList.remove('eoy-all-button-active');
      handleTabControlClick(value, idx);
    }
  };

  const isDisabled = () => {
    const hasSectorFilters = checkedSectorFilters.some(value => value);
    const hasCheckedItems = checkedItems.length > 0;

    return !(hasSectorFilters || hasCheckedItems);
  };

  const sortByName = () => {
    const sortedData = sortedByName(members, [...filteredData]);
    setFilteredData(sortedData.length ? sortedData : getSearchStyle());
  };

  const sortByCountry = () => {
    const sortedData = sortedByCountry(members, [...filteredData]);
    setFilteredData(sortedData.length ? sortedData : getSearchStyle());
  };

  const buttonAllClicked = () => {
    removeTabListActiveSelection();
    document.getElementById('selectAllBtn')?.classList.add('eoy-all-button-active');
    // set -1 for deselecting all tab items
    handleTabControlClick('', -1);
  };

  return (
    <>
      {!fontsLoaded && (
        <div className="eoy-progress-loader">
          <MotifProgressBar isIndeterminate circle hideLabel />
        </div>
      )}
      <div className={`eoy-app ${dropdownIsOpen ? 'no-scroll' : ''}`}>
        <MetaTags data={metadata} id={memberId} />
        <MotifTheme />
        {/* HEADER */}
        <MotifHeader
          appHeaderName={siteTitle}
          className="eoy-header"
          fixed
          logo={
            <MotifHeaderLogo>
              <a href={eyUrl}>
                <img src={EYLogo} aria-hidden="true" alt="ey logo" />
                <span className="hide-for-accessibility">EY Logo - Home</span>
              </a>
            </MotifHeaderLogo>
          }
        />
        <CookieConsentComponent
          data={siteCookies}
          onClick={() => {
            navigate(`/cookies`);
          }}
        />
        {/* HERO */}
        <HeroComponent bkgImage={heroImage}>
          <h1 className="eoy-title">{heroTitle}</h1>
          <MotifFormField>
            <MotifSearch
              value={searchInputValue}
              items={[]}
              className="eoy-search-input"
              maxLength="50"
              title="search"
              aria-label="Search"
              onChange={(event: InputEvent) => {
                const searchTarget = event.target as HTMLInputElement;
                handleInputChange(searchTarget.value);
              }}
              placeholder={searchPlaceholder}
            />
          </MotifFormField>
          {/* Subnav Wrapper */}
          <div className="eoy-subnav-wrapper">
            <div className="eoy-aggregate">{getSelectedCount()}</div>
            <MotifDropdownPortal
              open={dropdownIsOpen}
              id="filter-dropdown"
              aria-labelledby="dropdown-trigger"
              className="eoy-dropdown-menu"
              portalClassName="eoy-dropdown-menu"
              handleClickOutside={handleClickOutside}
              onKeyDown={handleKeyDown}
              ref={ref}
              trigger={
                <MotifButton
                  variant="text"
                  type="button"
                  role="button"
                  onClick={handleDropdown}
                  id="dropdown-trigger"
                  className="eoy-dropdown-trigger"
                  aria-haspopup="listbox"
                  aria-expanded={dropdownIsOpen}
                  aria-label="Filters"
                >
                  <span>Filters {getFilteredDropdownCount()}</span>
                  <MotifIcon src={navigationIcExpandMore24px} />
                </MotifButton>
              }
            >
              <FocusLock shards={[ref]} disabled={!dropdownIsOpen}>
                <InFocusGuard>
                  <div className="eoy-dropdown-reset-wrapper">
                    <div className="eoy-dropdown-button-wrapper">
                      <span className="eoy-dropdown-sm-filter">
                        Filters {getFilteredDropdownCount()}
                      </span>
                      <AutoFocusInside className="eoy-dropdown-focus-wrapper">
                        <MotifButton
                          variant="text"
                          type="reset"
                          className="eoy-reset-button"
                          tabIndex={0}
                          disabled={isDisabled()}
                          onClick={() => {
                            handleResetButtonMobile();
                          }}
                        >
                          Reset
                        </MotifButton>
                      </AutoFocusInside>
                    </div>
                    <MotifIconButton
                      type="button"
                      onClick={() => setDropdownIsOpen(false)}
                      id="dropdown-close"
                      aria-label="Close Filter menu"
                      className="eoy-close-filter-button"
                      tabIndex={0}
                      title="Close Filter menu"
                    >
                      <MotifIcon src={navigationIcClose24px} />
                    </MotifIconButton>
                  </div>
                  <div className="eoy-filter-sm-options" role="listbox">
                    <h3 className="eoy-dropdown-sm-filter">Regions</h3>
                    <MotifTree>
                      {regionData.map((region) => (
                        <TreeComponent
                          item={region}
                          key={region.key}
                          checkedItems={checkedItems}
                          onChecked={handleChecked}
                          currentlyOpenItem={currentlyOpenItem}
                          onItemClick={handleNodeClick}
                        />
                      ))}
                    </MotifTree>
                    <h3 className="eoy-dropdown-sm-filter">Sectors</h3>
                    <CheckboxFilterComponent
                      checkedFilters={checkedSectorFilters}
                      onChange={(idx: number) => {
                        handleCheckboxChange(idx);
                        setDropdownIsOpen(true);
                      }}
                      filters={sectors}
                      members={getSearchStyle()}
                      className="eoy-dropdown-checkbox"
                      type="dropdown"
                    />
                  </div>
                  <div className="eoy-apply-sm-filter">
                    <MotifButton
                      variant="primary-alt"
                      size="large"
                      tabIndex={0}
                      onClick={() => setDropdownIsOpen(false)}
                      disabled={
                        checkedSectorFilters.filter((value) => value).length ===
                          0 && checkedItems.length === 0
                      }
                    >
                      Apply
                    </MotifButton>
                  </div>
                </InFocusGuard>
              </FocusLock>
            </MotifDropdownPortal>
          </div>
          {/* </div> */}
        </HeroComponent>
        {/* DIRECTORY BODY */}
        <div className="motif-container eoy-content-container">
          <div className="motif-row">
            <div className="motif-col-xs-12 motif-col-lg-9">
              <div className="eoy-option-wrapper">
                <div className="eoy-pagination-wrapper">
                  <MotifButton
                    onClick={buttonAllClicked}
                    size="small"
                    id="selectAllBtn"
                    className="eoy-select-all-button"
                    type="button"
                  >
                    ALL
                  </MotifButton>
                  <div className="eoy-left-nav-wrapper">
                    <MotifIconButton
                      className="eoy-pagination-button"
                      onClick={() => scrollTo('first')}
                      size="medium"
                      aria-label="Click here to see options"
                      type="button"
                      data-testid="scroll-first"
                      disabled={isLeftDisabled}
                    >
                      <MotifIcon src={navigationIcFirstPage24px} />
                    </MotifIconButton>
                    <MotifIconButton
                      className="eoy-pagination-button"
                      onClick={() => scrollTo('left')}
                      size="medium"
                      aria-label="scroll-left"
                      data-testid="scroll-left"
                      type="button"
                      disabled={isLeftDisabled}
                    >
                      <MotifIcon src={navigationIcChevronLeft24px} />
                    </MotifIconButton>
                  </div>
                  <MotifTabNavigation
                    ref={scrollContainerRef}
                    className="eoy-tab-nav"
                    id="tabNav"
                    data-testid="year-scroller"
                  >
                    {yearFilters.map((year: YearFilters, idx: number) => (
                      <MotifTabControl
                        controlled="true"
                        onClick={() => getClickTab(year.value, idx)}
                        key={idx}
                        onTouchEnd={() => getTouchTab(year.value, idx)}
                      >
                        {year.label}
                      </MotifTabControl>
                    ))}
                  </MotifTabNavigation>
                  <div className="eoy-right-nav-wrapper">
                    <MotifIconButton
                      className="eoy-pagination-button"
                      onClick={() => scrollTo('right')}
                      size="medium"
                      aria-label="Click here to see options"
                      type="button"
                      data-testid="scroll-right"
                      disabled={isRightDisabled}
                    >
                      <MotifIcon src={navigationIcChevronRight24px} />
                    </MotifIconButton>
                    <MotifIconButton
                      className="eoy-pagination-button"
                      onClick={() => scrollTo('last')}
                      size="medium"
                      aria-label="Click here to see options"
                      type="button"
                      data-testid="scroll-last"
                      disabled={isRightDisabled}
                    >
                      <MotifIcon src={navigationIcLastPage24px} />
                    </MotifIconButton>
                  </div>
                </div>

                {/* SORTING */}
                <MotifFormField className="eoy-sort-container">
                  <MotifLabel id="select-label">Sort By</MotifLabel>
                  <MotifSelect
                    ariaLabelledBy="select-label"
                    className="eoy-select-container"
                    value="name"
                    visibleOptions={4}
                  >
                    <MotifOption
                      data-testid="sort-by-name"
                      value="name"
                      onClick={sortByName}
                    >
                      Name
                    </MotifOption>
                    <MotifOption
                      data-testid="sort-by-country"
                      value="country"
                      onClick={sortByCountry}
                    >
                      Country
                    </MotifOption>
                  </MotifSelect>
                </MotifFormField>
              </div>
              {/* CARDS */}
              <div className="motif-row">
                {filteredData.map((node: Member, idx: number) => {
                  let count = 0;
                  if (
                    getPaginationBounds({
                      currentPage: Number(currentPage),
                      itemsPerPage,
                      idx
                    })
                  ) {
                    count++;
                    const key = `${node.id}_${count}_${idx}`;

                    return (
                      <CardComponent
                        data={node}
                        key={key}
                        onClick={() => handleShow(node.id)}
                      />
                    );
                  }

                  return false;
                })}
                {filteredData.length === 0 ? (
                  <div className="eoy-no-results-container">
                    <h2>No result found</h2>
                    <p>We can’t find anyone matching your search.</p>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {getMaxPages({ amount: filteredData.length, itemsPerPage }) >
                1 && (
                <div className="motif-row">
                  <div className="motif-col-xs-12 eoy-pagination-container">
                    <MotifPagination
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                      min={1}
                      max={getMaxPages({
                        amount: filteredData.length,
                        itemsPerPage
                      })}
                      inputProps={{
                        'aria-label': 'pagination-current-page'
                      }}
                      firstButtonProps={{
                        title: 'Go to first page'
                      }}
                      lastButtonProps={{
                        title: 'Go to last page'
                      }}
                      prevButtonProps={{
                        title: 'Go to previous page'
                      }}
                      nextButtonProps={{
                        title: 'Go to next page'
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            {/* SIDEBAR - FILTERS */}
            <div className="motif-col-lg-3 eoy-filter-hide">
              <div className="eoy-filter-wrapper">
                <h2 className="eoy-filter-title">Regions</h2>
                <MotifButton
                  variant="text"
                  type="reset"
                  className="eoy-reset-button"
                  disabled={!checkedItems.length}
                  onClick={() => {
                    setCheckedItems([]);
                    setCurrentlyOpenItem(null);
                  }}
                >
                  Reset
                </MotifButton>
                <MotifTree>
                  {regionData.map((region) => (
                    <TreeComponent
                      item={region}
                      key={region.key}
                      checkedItems={checkedItems}
                      onChecked={handleChecked}
                      currentlyOpenItem={currentlyOpenItem}
                      onItemClick={handleNodeClick}
                    />
                  ))}
                </MotifTree>
              </div>
              <div className="eoy-filter-wrapper">
                <h2 className="eoy-filter-title">Sectors</h2>
                <MotifButton
                  variant="text"
                  type="reset"
                  className="eoy-reset-button"
                  data-testid="reset-button"
                  disabled={
                    !checkedSectorFilters.filter((value) => value).length
                  }
                  onClick={() => {
                    handleResetButton();
                  }}
                >
                  Reset
                </MotifButton>
                <CheckboxFilterComponent
                  checkedFilters={checkedSectorFilters}
                  onChange={(idx: number) => {
                    handleCheckboxChange(idx);
                    setDropdownIsOpen(true);
                  }}
                  filters={sectors}
                  members={getSearchStyle()}
                  className=""
                  type="layout"
                />
              </div>
            </div>
          </div>
        </div>

        <FooterComponent
          footerCTA={footerCTA}
          footerLink={footerLink}
          footerContent={footerContent}
          footnote={footnote}
          footerLinks={footerLinks}
        />
        {modalVisible && (
          <ModalWrapper
            handleClose={handleClose}
            modalVisible={modalVisible}
            data={selectedNode[0]}
          />)
        }
      </div>
    </>
  );
};

IndexPage.defaultProps = {
  itemsPerPage: 20,
  searchAll: false
};


export default IndexPage;
